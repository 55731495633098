<template>
  <page-layout :show-back-button="false">
    <span slot="header-name">{{ $tf('audit_logs') }}</span>
    <audit-logs-table-navigation slot="header-content-navigation" ref="navigation" :state="state" />
    <div slot="content">
      <audit-logs-table
        :items="state.items"
        :loading="state.loading"
        @detail="handleDetailEvent"
        @go-to-user="handleGotoUserEvent"
        @go-to-page="handleGotoPageEvent"
      />
      <audit-logs-details-dialog v-if="selected" :item="selected" @close="handleDetailEvent" />
    </div>
  </page-layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import AuditLogsTableNavigation from '@/components/tables/navigation';
import AuditLogsDetailsDialog from './audit-logs-details-dialog';
import AuditLogsTable from './audit-logs-table';
import PageLayout from '@/components/page/layout';

@Component({
  components: {
    PageLayout,
    AuditLogsTableNavigation,
    AuditLogsDetailsDialog,
    AuditLogsTable
  }
})
export default class AuditLogsPage extends Vue {
  selected = null;

  get state() {
    return this.$store.state.audit_logs;
  }

  get pages() {
    return this.state.ObjectPages;
  }

  handleDetailEvent(item = null) {
    this.selected = item;
  }

  handleGotoUserEvent(userId) {
    this.$router.push({ name: 'userId', params: { id: userId } });
  }

  handleGotoPageEvent(objectType, objectId) {
    const resolver = this.pages[objectType];
    resolver && this.$router.push(resolver(objectId));
  }
}
</script>

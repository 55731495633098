<template>
  <el-table :data="items" :size="tableSize" v-loading="loading" @row-click="handleRowClickEvent" row-class-name="audit-logs-table__row" stripe>
    <el-table-column prop="user_login" :label="$tf('user')" width="128">
      <div slot-scope="{ row }" class="audit-logs-table__column-id" v-text="row.user_login"></div>
    </el-table-column>
    <el-table-column prop="request_ip" :label="$tf('request_ip')" width="120" />
    <el-table-column prop="device_uid" :label="$tf('device_uid')" width="300" />
    <el-table-column prop="action_type" :label="$tf('action_type')" min-width="200" :formatter="computeActionTypeLabel" />
    <el-table-column prop="object_type" :label="$tf('object_type')" min-width="128" :formatter="computeObjectTypeLabel" />
    <el-table-column prop="object_id" :label="$tf('object_id')" width="120">
      <div slot-scope="{ row }" class="audit-logs-table__column-id" v-text="row.object_id"></div>
    </el-table-column>
    <el-table-column prop="created_date" :label="$tf('created_date')" :formatter="formatCreatedDate" width="180" />
  </el-table>
</template>

<script>
import { formatDateTime } from '@/apps/common/filters';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    items: { type: Array, required: true },
    loading: { type: Boolean, default: false }
  }
})
export default class AuditLogsTable extends Vue {
  events = {
    user_id: this.dispatchGotoUserEvent,
    object_id: this.dispatchGotoPageEvent
  };

  get state() {
    return this.$store.state.audit_logs;
  }

  get tableSize() {
    const { sizes } = this.$vars;
    return sizes.table || sizes.common;
  }

  get datesMap() {
    return this.items.reduce(appendCreatedDateToMap, {});
  }

  formatCreatedDate({ id }) {
    return formatDateTime(this.datesMap[id]);
  }

  handleRowClickEvent(item, { property }) {
    (this.events[property] || this.dispatchDetailEvent)(item);
  }

  dispatchDetailEvent(item) {
    this.$emit('detail', item);
  }

  dispatchGotoUserEvent({ user_id }) {
    user_id != null && this.$emit('go-to-user', user_id);
  }

  dispatchGotoPageEvent(item) {
    const { object_type, object_id } = item;
    object_id != null && this.$emit('go-to-page', object_type, item);
  }

  computeActionTypeLabel({ action_type }) {
    return this.$tf(this.state.ActionTypesI18n[action_type] ?? action_type);
  }

  computeObjectTypeLabel({ object_type }) {
    return this.$tf(this.state.ObjectTypesI18n[object_type] ?? object_type);
  }
}

function appendCreatedDateToMap(datesMap, { id, created_date }) {
  datesMap[id] = Date.parse(created_date);
  return datesMap;
}
</script>

<style lang="stylus">
.audit-logs-table {
  &__row {
    cursor: pointer;
  }

  &__column-id:hover {
    color: rgba(255, 255, 255, 0.95);
    text-decoration: underline;
  }
}
</style>
